
const nav = () => {
  const btn = document.querySelector('.sandwich-btn');
  const body = document.body;
  if (!btn) {
    return;
  }

  const open = () => {
    body.classList.add('show-mobile-nav');
  };

  const close = () => {
    body.classList.remove('show-mobile-nav');
  };

  const toggle = () => {
    body.classList.toggle('show-mobile-nav');
  };

  btn.addEventListener('click', () => {
    btn.blur();
    toggle();
  });

  document.querySelectorAll('.header-nav a').forEach((link) => {
    link.addEventListener('click', close);
  });

};

export {nav};
