import {nav} from './nav';


const init = () => {
  document.addEventListener('turbolinks:load', () => {
    nav();

    const main_visual = document.querySelector('#main_visual');
    if (main_visual) {
      main_visual.addEventListener('load', () => {
        document.body.classList.add('loaded');
      })
    }
  });
}

export default init;
